import React from "react";
import About from "../components/about";
import Art from "../components/art";
import FAQ from "../components/faq";
import Hero from "../components/hero";
import PageLayout from "../components/page-layout";
import Roadmap from "../components/roadmap";

const Home = () => {
  return (
    <PageLayout>
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <div className="grid pt-16 sm:pt-36 gap-32">
        <Hero />
        <About />
        <Roadmap />
        <Art />
        <FAQ />
      </div>
    </PageLayout>
  );
};

export default Home;
