import React from "react";
import { Accordion, AccordionItem, AccordionPanel } from "../elements/accordion";
import { FaqContent } from "../constants/faqcontent";
import { Tab, Tabs } from "../elements/tabs";

const FAQ = () => {
  return (
    <div
      id="faq"
      className="bg-transparent text-white container rounded-lg mx-auto flex flex-col items-center py-12 sm:py-24"
    >
      <h1 className="text-7xl pb-5 font-black">FAQ</h1>
      <div className="w-full rounded-lg lg:w-3/4 text-center">
        <Tabs variant={3}>
          <Tab title="General">
            <Accordion>
              {FaqContent.general.map((content, i) => (
                <>
                  <AccordionItem key={i} toggle={`panel-${i}`}>
                    {content.question}
                  </AccordionItem>
                  <AccordionPanel id={`panel-${i}`}>
                    <p className="mb-4 pt-2">{content.answer}</p>
                  </AccordionPanel>
                </>
              ))}
            </Accordion>
          </Tab>
          <Tab title="NFT Mint">
            <Accordion>
              {FaqContent.mint.map((content, i) => (
                <>
                  <AccordionItem key={i} toggle={`panel-${i}`}>
                    {content.question}
                  </AccordionItem>
                  <AccordionPanel id={`panel-${i}`}>
                    <p className="mb-4 pt-2">{content.answer}</p>
                  </AccordionPanel>
                </>
              ))}
            </Accordion>
          </Tab>
          <Tab title="Financial">
            <Accordion>
              {FaqContent.financial.map((content, i) => (
                <>
                  <AccordionItem key={i} toggle={`panel-${i}`}>
                    {content.question}
                  </AccordionItem>
                  <AccordionPanel id={`panel-${i}`}>
                    <p className="mb-4 pt-2">{content.answer}</p>
                  </AccordionPanel>
                </>
              ))}
            </Accordion>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default FAQ;
