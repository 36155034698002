import React from "react";
import bullGif from "../assets/images/bull-with-lightning.webp";

const About = () => {
  return (
    <div id="about" className="bg-transparent container text-white rounded-lg mx-auto items-center py-4 sm:py-24">
      <h1 className="text-7xl pb-5 font-black text-yellow-400 text-center">About</h1>
      <div className="sm:grid sm:grid-rows-2 sm:grid-rows-1 sm:grid-cols-2">
        <img className="rounded-lg p-5 sm:w-3/4 sm:my-auto" src={bullGif} />
        <div className="sm:grid sm:grid-rows-2 font-extrabold p-5">
          <div>
            <h2 className="text-center text-yellow-500 norse-bold text-2xl sm:text-4xl sm:pb-5">Overview</h2>
            <p className="p-5 text-normal sm:text-xl pb-16">
              The Asgardian Bulls Club is a collection of 7,812 unique Bull NFTs recently released from Asgard that have
              stormed the Avalanche blockchain. Holding one of these unique NFTs will grant membership into Asgard to
              join our bulls and be entered into our weekly giveaways, whitelist on future projects, and additional
              membership perks.
            </p>
          </div>
          <div>
            <h2 className="text-center text-yellow-500 norse-bold text-2xl sm:text-4xl sm:pb-5">Inspiration</h2>
            <p className="p-5 text-normal sm:text-xl">
              The Asgardian Bulls Club founders truly believe that these node protocols can change lives with the
              passive income they provide. Initially, it started by our founder, Pedro, wanting to create nodes for his
              family to help them through some difficult times, but that sparked the idea of helping more than just
              family and being able to change many lives with these giveaways, that is where the idea for the Asgardian
              Bulls Club started. We want to make a positive impact on as many lives as possible through these
              giveaways.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
