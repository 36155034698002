import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const Hero = () => {
  return (
    <div className="container mx-auto flex flex-col items-center py-6">
      <div className="w-full flex items-center flex-col mb-5 sm:mb-10">
        <StaticImage
          className="rounded-lg sm:w-1/3 mr-4 sm:mr-14"
          src="../assets/images/community-logo.webp"
          alt="Asgardian Bull"
        />
        <a href="https://asgardian-bulls-nft.gitbook.io/asgardian-bulls-whitepaper/" target="_blank">
          <button className="mt-10 neon-yellow text-lg sm:text-xl font-bold rounded-lg border px-4 sm:px-10 py-2 sm:py-4 ml-5 sm:ml-0">
            Whitepaper
          </button>
        </a>
      </div>
      <div className="w-1/2 justify-around flex pb-52"></div>
    </div>
  );
};

export default Hero;
