import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Art = () => {
  return (
    <div id="art" className="bg-transparent container rounded-lg mx-auto flex flex-col items-center py-4 sm:py-24">
      <h1 className="text-7xl pb-5 font-black text-yellow-400">Art</h1>
      <p className="text-white text-center text-xl sm:text-2xl p-5 w-4/5 sm:w-4/5">
        While all Asgardian Bulls holders gain equal entry into our weekly giveaways, we believe it is still important
        to have a good looking collection. Here are just a few examples of the bulls that can be minted!
      </p>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={2500}
        showIndicators={false}
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        className="w-4/5 sm:w-2/5"
      >
        <div>
          <StaticImage className="object-contain" src="../assets/images/bull-6.webp" alt="Asgardian Bull" />,
        </div>
        <div>
          <StaticImage className="object-contain" src="../assets/images/bull-4.webp" alt="Asgardian Bull" />,
        </div>
        <div>
          <StaticImage className="object-contain" src="../assets/images/bull-3.webp" alt="Asgardian Bull" />,
        </div>
        <div>
          <StaticImage className="object-contain" src="../assets/images/bulls-7.webp" alt="Asgardian Bull" />,
        </div>
        <div>
          <StaticImage className="object-contain" src="../assets/images/bulls-8.webp" alt="Asgardian Bull" />,
        </div>
        <div>
          <StaticImage className="object-contain" src="../assets/images/bulls-9.webp" alt="Asgardian Bull" />,
        </div>
        <div>
          <StaticImage className="object-contain" src="../assets/images/bulls-10.webp" alt="Asgardian Bull" />,
        </div>
        <div>
          <StaticImage className="object-contain" src="../assets/images/bull-11.png" alt="Asgardian Bull" />,
        </div>
      </Carousel>
    </div>
  );
};

export default Art;
