import React, { ReactElement } from "react";

const thorHammerSvg: ReactElement = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="358.685px"
    height="358.685px"
    viewBox="0 0 358.685 358.685"
    fill="rgb(71 85 105)"
  >
    <path
      d="M282.531,0v10.809H76.135V0H34.359v176.289h41.775v-19.584h79.777v201.98h43.232v-201.98h83.395v19.584h41.787V0H282.531
				z M70.73,10.814V156.71v14.187H39.758V5.404H70.73V10.814z M216.593,40.313L178.25,76.012l22.557,1.261
				c1.23,0.063,2.317,0.861,2.731,2.054c0.402,1.174,0.085,2.48-0.864,3.32l-54.917,49.125c-0.552,0.496-1.285,0.781-2.054,0.781
				c-0.811,0-1.585-0.327-2.17-0.91c-1.147-1.149-1.198-3.014-0.12-4.224l32.525-36.353l-31.615,1.744c0,0,0,0-0.021,0
				c-1.357,0-2.396-0.681-2.9-1.732c-0.534-1.132-0.351-2.465,0.486-3.39l46.024-51.446c0.579-0.639,1.402-1.021,2.272-1.021
				l24.26-0.228c1.279,0,2.426,0.781,2.882,1.943C217.824,38.112,217.518,39.439,216.593,40.313z M318.921,170.89h-30.973v-14.186
				V10.814v-5.41h30.973V170.89z"
    />
  </svg>
);

const listItemClasses = "mb-10 ml-3";
const svgSpanClasses = "flex absolute -left-0 sm:-left-5 justify-center items-center w-10 h-10 bg-white rounded-lg";
const svgSpanCompletedClasses =
  "flex absolute -left-0 sm:-left-5 justify-center items-center w-10 h-10 bg-yellow-500 rounded-lg";
const svgSpanPartialCompletedClasses =
  "flex absolute -left-0 sm:-left-5 justify-center items-center w-10 h-10 bg-gradient-to-b from-yellow-500 to-white rounded-lg";
const headingClasses = "flex items-center mb-1 pl-5 text-lg sm:text-xl font-semibold";
const timeClasses = "block mb-2 pl-5 font-normal leading-none sm:text-xl";
const contentClasses = "pl-5 mb-4 font-sans text-base font-normal p-1 sm:text-lg tracking-wider";

const Roadmap = () => {
  return (
    <div
      id="roadmap"
      className="bg-transparent text-white container rounded-lg mx-auto flex flex-col items-center py-4 sm:py-24"
    >
      <h1 className="text-7xl pb-5 font-black text-yellow-400">Roadmap</h1>
      <ol className="ml-5 sm:ml-0 sm:relative border-l border-yellow-400">
        <li className={listItemClasses}>
          <span className={svgSpanCompletedClasses}>{thorHammerSvg}</span>
          <h3 className={headingClasses}>Asgardian Bulls Club Inception</h3>
          <time className={timeClasses}>January 2022</time>
          <p className={contentClasses}>
            The Asgardian Bulls Club idea was born out of inspiration of providing benefit to the Defi and node protocol
            community
          </p>
        </li>
        <li className={listItemClasses}>
          <span className={svgSpanCompletedClasses}>{thorHammerSvg}</span>
          <h3 className={headingClasses}>Discord Launch</h3>
          <time className={timeClasses}>February, 2022</time>
          <p className={contentClasses}>The Asgardian Bulls Discord was opened to the public community</p>
        </li>
        <li className={listItemClasses}>
          <span className={svgSpanCompletedClasses}>{thorHammerSvg}</span>
          <h3 className={headingClasses}>Website Launch</h3>
          <time className={timeClasses}>March 21, 2022</time>
          <p className={contentClasses}>The Asgardian Bulls Club official website was launched</p>
        </li>
        <li className={listItemClasses}>
          <span className={svgSpanCompletedClasses}>{thorHammerSvg}</span>
          <h3 className={headingClasses}>Asgardian Bulls NFT Mint</h3>
          <time className={timeClasses}>March 25 &#38; 26th 12PM CST</time>
          <p className={contentClasses}>
            The Asgardian Bulls Club will open up whitelist minting on March 25th 12PM CST and public minting on March
            26th 12PM CST
          </p>
        </li>
        <li className={listItemClasses}>
          <span className={svgSpanPartialCompletedClasses}>{thorHammerSvg}</span>
          <h3 className={headingClasses}>Treasury Invesments</h3>
          <time className={timeClasses}>Late March through early April, 2022</time>
          <div className={contentClasses}>
            <p>
              The team will proactively invest the mint proceeds into sustainable, long term and well researched Defi
              and node protocol projects. The initial investments were chosen after thorough research. Further
              invetments will be put up to a community vote for verified bulls
            </p>
            <p>The initial (5) invetments will be a follows</p>
            <ul className="list-disc list-inside">
              <li>
                <a className="underline" href="https://www.thor.financial/" target="_blank">
                  Thor Financial (Avalanche)
                </a>
              </li>
              <li>
                <a className="underline" href="https://projectx.financial/" target="_blank">
                  ProjectX Financial (Avalanche)
                </a>
              </li>
              <li>
                <a className="underline" href="https://strongblock.com/" target="_blank">
                  StrongBlock (Ethereum)
                </a>
              </li>
              <li>
                <a className="underline" href="https://nodegrid.gitbook.io/whitepaper/" target="_blank">
                  NodeGrid (Binance Smart Chain)
                </a>
              </li>
              <li>
                <a
                  className="underline"
                  href="https://alephfinance.gitbook.io/aleph-finance/project-information/an-introduction-to-aleph-finance"
                  target="_blank"
                >
                  Aleph Finance (Avalanche)
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li className={listItemClasses}>
          <span className={svgSpanClasses}>{thorHammerSvg}</span>
          <h3 className={headingClasses}>Mint Closed</h3>
          <time className={timeClasses}>April 15, 2022</time>
          <p className={contentClasses}>The public mint will be closed on April 15th until further notice</p>
        </li>
        <li className={listItemClasses}>
          <span className={svgSpanClasses}>{thorHammerSvg}</span>
          <h3 className={headingClasses}>First Giveaway</h3>
          <time className={timeClasses}>April 22, 2022</time>
          <p className={contentClasses}>
            The first NFT holder giveaway of returns on the treasury investments will be held. More detail to come
            closer to this date on giveaway and reward number
          </p>
        </li>
        <li className={listItemClasses}>
          <span className={svgSpanClasses}>{thorHammerSvg}</span>
          <h3 className={headingClasses}>Future Goals</h3>
          <time className={timeClasses}>As soon as possible!</time>
          <div className={contentClasses}>
            <ul className="list-disc list-inside">
              <li>
                Apply to and join the{" "}
                <a className="underline" href="https://mobile.twitter.com/uot_foundation" target="_blank">
                  Union of Transparency (UoT)
                </a>{" "}
                as one of the first collections of its kind to join. We've already had very positive video meetings with
                their founder
              </li>
              <li>
                Collaborations with other projects to benefit all Asgardian Bulls. We're continously seeking out ways to
                partner with like-minded communities
              </li>
              <li>
                Play To Earn mechanics that the greater community has grown to show great interest in. All future
                endeavors will keep the original genesis collection of Asgardian Bulls at the forefront of these
                initiatives
              </li>
            </ul>
          </div>
        </li>
      </ol>
    </div>
  );
};

export default Roadmap;
