import React from "react";
import { ReactElement } from "react";

type FAQ = {
  general: FaqElement[];
  mint: FaqElement[];
  financial: FaqElement[];
};

type FaqElement = {
  id: number;
  question: string;
  answer: string | ReactElement;
};

export const FaqContent: FAQ = {
  general: [
    {
      id: 1,
      question: "Why invest in Asgardian Bulls?",
      answer:
        "The team's main focus is to create a more affordable entry into passive income opportunities for the average investor and help them on their way to financial freedom. Goal is to help a larger percentage of investors, not just make a handful rich",
    },
    {
      id: 2,
      question: "Why should investors trust the Asgardian Bulls Team?",
      answer:
        "Our team is dedicated to being fully transparent with the community and investors. Founder has fully doxxed prior to mint, full core team has doxxed with influencers privately, team will undergo a KYC and Asgardian Bulls Club will be applying to join the Union of Transparency.",
    },
    {
      id: 3,
      question: "What is the long term goal of the project?",
      answer:
        "We want to create as much value to our holders as possible via weekly giveaways, educational content, early access to projects, and a potential P2E functionality to add additional income opportunities.",
    },
    {
      id: 4,
      question: "How does rarity of the Bull play in this?",
      answer:
        "The rarity of the bulls will not affect the chances of winning one of the giveaways. For now, the rarity will only affect the potential value in the secondary market, but we will be incorporating rarity into future utility or whitelist opportunities",
    },
  ],
  mint: [
    {
      id: 1,
      question: "What is the mint price?",
      answer: "Whitelist price is 0.8 AVAX and the public price is 1 AVAX",
    },
    {
      id: 2,
      question: "How many bulls can I mint?",
      answer: "Each wallet will be able to mint up to 30 bulls (whitelist and free mints count towards the 30)",
    },
    {
      id: 3,
      question: "How many bulls can I mint per transaction?",
      answer: "Each transaction can mint up to 10 bulls at once.",
    },
    {
      id: 4,
      question: "What happens if all 7,812 NFTs are not minted?",
      answer:
        "The mint will be closed on April 15, 2020 and we will begin giveaways with current holders on April 22, 2022.",
    },
    {
      id: 5,
      question: "Are NFT holders eligible to win more than one node in the giveaway?",
      answer:
        "Each NFT can only win 1 node per week. If someone is holding multiple bulls, they can win more than 1 node if more than 1 of their bulls is picked as a winner.",
    },
    {
      id: 6,
      question: "If my bull isn't selected in the giveaway do I have a higher chance of winning in the future?",
      answer:
        'Yes! We want to reward as many of our investors as possible so we have introduced what we are calling a “weighted giveaway system".',
    },
    {
      id: 7,
      question: "What is the weighted giveaway system?",
      answer:
        "Our custom built weighted system is backed by our giveaway contract and increases the odds of the NFT's that do not win each week. Essentially, each NFT will begin our weekly giveaways with the same chance of winning the giveaway. After the first giveaway, the NFTs that were not chosen will have their likelihood of being chosen in the future increased when compared to those that have already won. We will reset all holders' chances of winning to be equal on a periodic basis, to essentially reset the playing field. This periodic basis is still being determined but we estimate it will be approximately every 4-6 months.",
    },
  ],
  financial: [
    {
      id: 1,
      question: "How are investments chosen?",
      answer:
        "These investments will share our vision for long term growth, sustainability, and transparency. The team has chosen projects with trustworthy and proven teams for the initial investments. Further investments will be decided with community input, and a final vote on a tailored list that meets the team's criteria.",
    },
    {
      id: 2,
      question: "How will the weekly revenue be distributed?",
      answer:
        "Weekly revenue will be distributed into 3 categories: 45% for compounding, 40% for the weekly giveaways, and 15% will be allocated to the team treasury.",
    },
    {
      id: 3,
      question: "Why is 15% allocated for the team treasury?",
      answer: (
        <div>
          The team treasury will serve multiple purposes to enable future investments with a significant position
          <ul className="list-disc list-inside">
            <li>Add to current investments during market pullbacks</li>
            <li>For future project initiatives, such as official merch, non-crypto giveaways, etc.</li>
            <li>
              For any additional expenses necessary for project maintenance and team pay and to fund future educational
              classes conferences and seminars
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 4,
      question: "How many winners do you expect per week?",
      answer:
        "There are estimated to be 50-70 node winners in the first giveaway. This will vary based on prices. The number of winners will increase each week with our compounding strategy.",
    },
    {
      id: 5,
      question: "Are there royalties involved for second market sales?",
      answer:
        "Yes, we plan on setting 7% for royalties. These royalties will be used for for future investments to improve the project.",
    },
    {
      id: 6,
      question: "Will the winners of the giveaways be rewarded in the native token of the node that they won?",
      answer:
        "Yes, winners will be rewarded with tokens from the respective investments (Thor, Strong, PXT, etc.). This gives the winner the choice to create their node/invest and begin earning passive income, or sell and convert to whatever token they prefer.",
    },
  ],
};
